
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import VDPopupListbox from "@/components/pickers/VDPopupListbox.vue";
import {
  RoleType,
  useGetSelectableRolesQuery,
  GetSelectableRolesQuery,
  RoleAssigneeType,
} from "@/graphql/types";
import GroupedItems from "@/models/GroupedItems";
import { useResult } from "@vue/apollo-composable";
import { defineComponent, PropType, Ref, ref, watch, computed } from "vue";

export default defineComponent({
  components: {
    VDPopupListbox,
  },
  emits: ["addedRole"],
  props: {
    addedRoles: {
      type: Array as PropType<RoleAssigneeType[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // VDOM
    const listbox = ref<VDPopupListboxClass>();

    // Refs
    const isEnabled = ref(false);

    // Data
    const getRolesResponse = useGetSelectableRolesQuery(() => ({
      enabled: isEnabled.value,
      fetchPolicy: "network-only",
    }));

    //Computed
    const loading = computed(() => {
      return getRolesResponse.loading.value;
    });

    const error = computed(() => {
      return getRolesResponse.error.value;
    });

    return {
      roles: useGroupedRolesByApplication(getRolesResponse.result),
      listbox,
      loading,
      error,
      toggle: (event: Event) => {
        isEnabled.value = true;
        listbox.value?.toggle(event);
      },
    };
  },
});

function useGroupedRolesByApplication(
  rolesQuery: Ref<GetSelectableRolesQuery | undefined>
) {
  const groupedRoles = ref<GroupedItems<RoleType>[]>([]);

  watch(
    () => useResult(rolesQuery).value,
    (newValue) => {
      const map: { [name: string]: RoleType[] } = {};

      newValue?.forEach((element) => {
        if (element?.application?.name && map[element.application.name]) {
          map[element.application.name].push(element);
        } else if (element?.application?.name) {
          map[element.application.name] = [element];
        }
      });

      groupedRoles.value = Object.keys(map)
        .sort()
        .map((element) => {
          return {
            name: element,
            items: map[element].sort(
              (a, b) => a.name?.localeCompare(b.name ?? "") ?? -1
            ),
          };
        });
    },
    { deep: true }
  );

  return groupedRoles;
}
